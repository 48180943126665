import {Component, OnInit, AfterViewChecked} from '@angular/core';
import {Event} from "../../models/event";
import {MainService} from "../../services/main.service";
import {Title} from "@angular/platform-browser";
import {EventGroup} from "../../models/event-group";
import {Tag} from "../../models/tag";

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.sass']
})
export class EventsComponent implements OnInit, AfterViewChecked {

  init = false;
  resizeItem = false;
  title = 'События | Актёрское агентство ОБЪЕКТИВ';
  headerTitle = 'События';

  nearest$: Event[] = null;
  upcoming$: Event[] = null;
  past$: Event[] = null;
  tags: Tag[] = null;

  eventDates: Date[];

  config = {
    dots: false,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: '<i class="icon-slick-next"></i>',
    prevArrow: '<i class="icon-slick-prev"></i>',
  };

  counter: HTMLDivElement;

  constructor(private titleService: Title, private mainService: MainService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.mainService.loadEventTags().subscribe((data: Tag[]) => {
      this.tags = data;
    });

    this.mainService.loadNearestSameDayEvents().subscribe((data: Event[]) => {
      this.nearest$ = data.length > 0 ? data : null;
      if (data && data.length > 0){
        this.mainService.loadUpcomingEvents(data.map(function (value) {
          return value.id;
        })).subscribe((upcoming: Event[]) => {
          if (upcoming.length > 0){
            this.upcoming$ = upcoming;
          }
        });
      } else {
        this.mainService.loadUpcomingEvents(null).subscribe((upcoming2: Event[]) => {
         if (upcoming2.length > 0){
           this.upcoming$ = upcoming2;
         }
        });
      }
    });

    this.mainService.loadPastEvents().subscribe((past: Event[]) => {
      if (past.length > 0){
        this.past$ = past;
      }
    });
  }

  loadEventGroup(tagId) {
    this.mainService.loadEventGroup(tagId).subscribe((data: EventGroup) => {
      this.nearest$ = data.nearest.length > 0 ? data.nearest : null;
      this.upcoming$ = data.upcoming.length > 0 ? data.upcoming : null;
      this.past$ = data.past.length > 0 ? data.past : null;

      if (data.nearest.length > 0){
        this.eventDates = data.nearest.map(x => new Date(x.date));
        let lastDate = this.eventDates[this.eventDates.length - 1];
        let calendar = $('.calendar-container');
        this.initCalendar(calendar, lastDate);
      }
    });
  }

  onTagClick(event, id: number) {
    let clickedTag = event.target;

    if (clickedTag.classList.contains('active')) {
      return;
    }

    let tags = Array.from(clickedTag.parentElement.children);
    tags.forEach(tag => {
      (tag as Element).classList.remove('active');
    });

    clickedTag.classList.add('active');

    this.loadEventGroup(id);
  }

  slickInit(e) {
    this.counter = document.createElement('div');
    this.counter.classList.add('slider--counter');

    let slick = e.slick;
    let currentSlide = slick.currentSlide;
    let i = (currentSlide ? currentSlide : 0) + 1;
    $(this.counter).html(`<span class="current">${i}</span> <span class="total"> / ${slick.slideCount}</span>`);

    $('.event-slider').append(this.counter);
  }

  afterChange(e) {
    let slick = e.slick;
    let currentSlide = slick.currentSlide;
    let i = (currentSlide ? currentSlide : 0) + 1;
    $(this.counter).html(`<span class="current">${i}</span> <span class="total"> / ${slick.slideCount}</span>`);
  }

  ngAfterViewChecked(): void {
    let calendar = $('.calendar-container');
    if (calendar.length > 0 && !this.init && this.nearest$.length > 0) {
      this.init = true;

      this.eventDates = this.nearest$.map(x => new Date(x.date));
      let eventDatesLength = this.eventDates.length;
      let lastDate = this.eventDates[eventDatesLength - 1];

      this.initCalendar(calendar, lastDate);

    }
  }

  getEventDates = (month, year, callback = null) => {
    this.mainService.loadEventDatesByMonth(month, year).subscribe((data: Date[]) => {
      if (data.length > 0) {
        data.forEach((date) => {
          this.selectDateOnCalendar(date);
        });

        if (callback) {
          callback();
        }
      }
    });
  }

  selectDateOnCalendar(date) {
    let calendar = $('.calendar-container');
    let day = calendar.find(`[data-date='${new Date(date)}']`);
    day.addClass('selected');
  }

  initCalendar(calendar, lastDate: Date){
    //@ts-ignore
    calendar.calendar({
      date: lastDate,
      prevButton: "<i class='fa fa-chevron-left'></i>",
      nextButton: "<i class='fa fa-chevron-right'></i>",
      monthYearSeparator: " ",
      showThreeMonthsInARow: true,
      enableMonthChange: true,
      enableYearView: true,
      showTodayButton: true,
      todayButtonContent: "Сегодня",
      highlightSelectedWeekday: false,
      highlightSelectedWeek: false,
      showYearDropdown: false,
      min: null,
      max: null,
      onChangeMonth: function (date) {
        // @ts-ignore
        markEventDatesOnCalendar(date);
      },
      onClickMonthNext: function (date) {
        // @ts-ignore
        markEventDatesOnCalendar(date);
      },
      onClickMonthPrev: function (date) {
        // @ts-ignore
        markEventDatesOnCalendar(date);
      },
    });

    this.getEventDates(lastDate.getMonth() + 1, lastDate.getFullYear());
  }

}
