import {Component, OnInit} from '@angular/core';
import {Social} from "../../../models/social";
import {MainService} from "../../../services/main.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {

  init = false;

  social: Social;

  constructor(private mainService: MainService) {
  }

  ngOnInit(): void {
    this.mainService.loadSocial().subscribe((data: Social) => {
      this.social = data;
    });
  }

  ngAfterViewChecked(): void {
    let burger = $('#burger');
    let nav = $('#mainNav');

    if (!this.init && burger && nav) {
      this.init = true;
      
      burger.click( function (e) {
        if (!burger.hasClass('is-open') && !(nav.hasClass('is-open'))) {
          $(this).addClass('is-open');
          nav.addClass('is-open');
          nav.find('a').click(function() {
            $(this).parents('#mainNav').removeClass('is-open');
            burger.removeClass('is-open');
          });
        } else {
          $(this).removeClass('is-open');
          nav.removeClass('is-open');
        }
      });
    }
  }

}
