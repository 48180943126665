import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-actors-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class ActorsHeaderComponent implements OnInit {

  title: string;
  routerLink: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.routerLink = this.router.url;

    if (this.routerLink === '/actors') {
      this.title = 'Актёры';
    } else if (this.routerLink === '/actresses') {
      this.title = 'Актрисы';
    } else if (this.routerLink === '/child-actors') {
      this.title = 'Новые лица';
    }
  }

}
