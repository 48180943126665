import {AfterContentChecked, Component, OnInit} from '@angular/core';
import {Actor} from "../../../models/actor";
import {MainService} from "../../../services/main.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-actor-slides',
  templateUrl: './actor-slides.component.html',
  styleUrls: ['./actor-slides.component.sass']
})
export class ActorSlidesComponent implements OnInit, AfterContentChecked {

  init = false;
  routerLink: string;
  categoryId = null;

  actors$: Actor[];
  actorPhotos: Actor[];

  config = {
    slidesToShow: 1,
    arrows: false,
    slidesToScroll: 1,
    cssEase: 'linear',
    autoplaySpeed: 1500
  };

  afterWhiteClass = "";

  constructor(private mainService: MainService, private router: Router) {
  }

  ngOnInit(): void {
    this.routerLink = this.router.url;

    if (this.routerLink === '/actors') {
      this.categoryId = 1;
    } else if (this.routerLink === '/actresses') {
      this.categoryId = 2;
    } else if (this.routerLink === '/child-actors') {
      this.categoryId = 3;
    }

    this.mainService.loadActors(this.categoryId)
      .subscribe((data: Actor[]) => {
        this.actors$ = data;

        if (this.actors$){
          this.mainService.loadActorsCoverPhotos(this.categoryId).subscribe((data: Actor[]) => {
            this.actors$.forEach(actor => {
              actor.photos = [data.find(a => a.id == actor.id).coverPhoto];
            });
          });
        }
      });

  }

  ngAfterContentChecked(): void {
    this.afterWhiteClass = this.mainService.getAfterWhiteClass();
  }

  initActorSlider(sliderSelector){
    let slider = $(sliderSelector);
    if (slider.length > 0){
      this.initSlider(slider);
    }
  }

  initSlider(slider){
    function slickPause(el) {
      el.slick('slickPause');
    }

    function slickPlay(el) {
      el.slick('slickPlay');
    }

    // @ts-ignore
    slider.slick(this.config);

    slider.on('mouseover', function () {
      slickPlay($(this));
    });

    slider.on('mouseout', function () {
      slickPause($(this));
    });

    $(window).on('scroll', function () {
      slickPause(slider);
    });
  }

  getActorPhotos(actor){
    if (!actor.photosLoaded){
      this.mainService.loadActorPhotos(actor.id).subscribe((data: Actor) => {
        actor.photos = data.photos;

        actor.photosLoaded = true;

        if (actor.photos){
          let isSliderInit = false;
          setInterval(() => {
            if ($(`#actor-item-slider-${actor.id}`).length && !isSliderInit){
              isSliderInit = true;
              this.initActorSlider(`#actor-item-slider-${actor.id}`);
            }
          }, 10);
        }
      });
    }

  }
}
