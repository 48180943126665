<!-- HEADER TAGS-->
<div class="container-fluid p-0 header-white">
  <div class="row m-0 w-100">
    <div class="col-12 col-md-3 heading">
      <h2>{{headerTitle}}</h2>
    </div>
    <div class="col-12 col-md-9 tags">
      <div class="talents-block">
        <span class="tag active" (click)="onTagClick($event, null)">#Все события</span>
        <span class="tag" *ngFor="let tag of tags" (click)="onTagClick($event, tag.id)">#{{tag.name}}</span>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid container-events-page">
  <div class="container-events-row-slider m-0 row" *ngIf="nearest$ as nearestEvents">
    <!-- NEAREST ONE -->
    <div class="col-12 event-slider" *ngIf="nearestEvents.length == 1">
      <div class="event-slider-item" *ngFor="let nearestEvent of nearestEvents">
        <div class="p-0 event-slider-image-block" [routerLink]="nearestEvent.link">
          <div class="event-slider-image">
            <img class="actor-item-image" [src]="nearestEvent.imageUrl" [alt]="nearestEvent.title"/>
          </div>
          <div class="actor-item-name event-actor-name">
            <div class="objective-icon">
              <img src="assets/img/objective.svg" alt="O">
            </div>
            <div class="name">
              {{nearestEvent.actor}}
            </div>
          </div>
        </div>
        <div class="event-content">
          <div class="event-slider-info" [routerLink]="nearestEvent.link">
            <h1 [title]="nearestEvent.title">{{nearestEvent.title}}</h1>
            <div class="event-text">{{nearestEvent.description}}</div>
          </div>
          <div class="event-content-data">
            <div class="event-content-data-day">
              <i class="icon-calendar"></i>
              <p>{{nearestEvent.dateTimeString}}</p>
            </div>
            <div class="event-content-data-location">
              <i class="icon-point"></i>
              <p>{{nearestEvent.address}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- NEAREST CAROUSEL -->
    <ngx-slick-carousel class="col-12 event-slider" *ngIf="nearestEvents.length > 1" [config]="config"
                        (init)="slickInit($event)" (afterChange)="afterChange($event)">
      <div ngxSlickItem class="event-slider-item" *ngFor="let nearestEvent of nearestEvents">
        <div class="p-0 event-slider-image-block" [routerLink]="nearestEvent.link">
          <div class="event-slider-image">
            <img class="actor-item-image" [src]="nearestEvent.imageUrl" [alt]="nearestEvent.title"/>
          </div>
          <div class="actor-item-name event-actor-name">
            <div class="objective-icon">
              <img src="assets/img/objective.svg" alt="O">
            </div>
            <div class="name">
              {{nearestEvent.actor}}
            </div>
          </div>
        </div>
       <div class="event-content">
         <div class="event-slider-info" [routerLink]="nearestEvent.link">
           <h1 [title]="nearestEvent.title">{{nearestEvent.title}}</h1>
           <div class="event-text">{{nearestEvent.description}}</div>
         </div>
         <div class="event-content-data">
           <div class="event-content-data-day">
             <i class="icon-calendar"></i>
             <p>{{nearestEvent.dateTimeString}}</p>
           </div>
           <div class="event-content-data-location">
             <i class="icon-point"></i>
             <p>{{nearestEvent.address}}</p>
           </div>
         </div>
       </div>
      </div>
    </ngx-slick-carousel>
  </div>

<!-- UPCOMING -->
  <div class="container-events-row-list m-0 pt-0 row" *ngIf="upcoming$ as upcomingEvents">
    <div class="events-page-heading">
      <h2>Предстоящие события</h2>
    </div>
    <div class="event-list-item pl-0 mt-3" *ngFor="let upcoming of upcomingEvents">
      <div class="list-item-image-event p-0 pointer" [routerLink]="upcoming.link">
        <div class="event-list-image">
          <img class="actor-item-image" [src]="upcoming.imageUrl" [alt]="upcoming.title"/>
        </div>
        <div class="actor-item-name event-actor-name">
          <div class="objective-icon">
            <img src="assets/img/objective.svg" alt="O">
          </div>
          <div class="name">
            {{upcoming.actor}}
          </div>
        </div>
      </div>
      <div class="list-item-info-event">
        <div class="event-content-grid">
          <div class="event-content-grid-text pointer" [routerLink]="upcoming.link">
            <h1>{{upcoming.title}}</h1>
            <p class="event-text">{{upcoming.description}}</p>
          </div>
          <div class="event-content-data">
            <div class="event-content-data-day">
              <i class="icon-calendar"></i>
              <p>{{upcoming.dateTimeString}}</p>
            </div>
            <div class="event-content-data-location">
              <i class="icon-point"></i>
              <p>{{upcoming.address}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- PAST -->
  <div class="container-events-row-list m-0 pt-0 row" *ngIf="past$ as pastEvents">
    <div class="events-page-heading">
      <h2>Прошедшие события</h2>
    </div>
    <div class="event-list-item pl-0 mt-3" *ngFor="let past of pastEvents">
      <div class="list-item-image-event p-0 pointer" [routerLink]="past.link">
        <div class="event-list-image">
          <img class="actor-item-image" [src]="past.imageUrl" [alt]="past.title"/>
        </div>
        <div class="actor-item-name event-actor-name">
          <div class="objective-icon">
            <img src="assets/img/objective.svg" alt="O">
          </div>
          <div class="name">
            {{past.actor}}
          </div>
        </div>
      </div>
      <div class="list-item-info-event">
        <div class="event-content-grid">
          <div class="event-content-grid-text pointer" [routerLink]="past.link">
            <h1>{{past.title}}</h1>
            <p class="event-text">{{past.description}}</p>
          </div>
          <div class="event-content-data">
            <div class="event-content-data-day">
              <i class="icon-calendar"></i>
              <p>{{past.dateTimeString}}</p>
            </div>
            <div class="event-content-data-location">
              <i class="icon-point"></i>
              <p>{{past.address}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
