<header>
  <div class="container-fluid p-0">
    <div class="row header-row">

      <div class="logo col-6 col-sm-4 col-md-3 col-lg-2">
        <a routerLink="/" routerLinkActive="active-link">
          <img src="assets/img/logo.svg" alt="logo">
        </a>
      </div>
      <div class="menu col-6 col-lg-8">
        <nav>
          <ul>
            <li><a routerLink="/actors">Актеры</a></li>
            <li><a routerLink="/actresses">Актрисы</a></li>
            <li><a routerLink="/child-actors">Новые лица</a></li>
            <li><a routerLink="/media">Медиа</a></li>
            <li><a routerLink="/events">События</a></li>
            <li><a routerLink="/to-actors">Актерам</a></li>
            <li><a routerLink="/contacts">Контакты</a></li>
          </ul>
        </nav>
      </div>

      <div class="media-menu col-6 col-sm-8 col-md-9 col-md-6">
        <button id="burger" class="open-main-nav">
          <span class="burger"></span>
        </button>
        <nav class="main-nav media-nav" id="mainNav">
          <div class="nav-bg"></div>
          <ul>
            <li><a routerLink="/actors">Актеры</a></li>
            <li><a routerLink="/actresses">Актрисы</a></li>
            <li><a routerLink="/child-actors">Новые лица</a></li>
            <li><a routerLink="/media">Медиа</a></li>
            <li><a routerLink="/events">События</a></li>
            <li><a routerLink="/to-actors">Актерам</a></li>
            <li><a routerLink="/contacts">Контакты</a></li>
            <li *ngIf="social" class="social">
              <div class="icon" *ngIf="social.facebook">
                <a [href]="social.facebook" target="_blank">
                  <i class="icon-facebook"></i>
                </a>
              </div>
              <div class="icon" *ngIf="social.instagram">
                <a [href]="social.instagram" target="_blank">
                  <i class="icon-instagram"></i>
                </a>
              </div>
              <div class="icon" *ngIf="social.youtube">
                <a [href]="social.youtube" target="_blank">
                  <i class="icon-youtube"></i>
                </a>
              </div>
              <div class="icon" *ngIf="social.kinoLift">
                <a [href]="social.kinoLift" target="_blank">
                  <i class="icon-k-social"></i>
                </a>
              </div>
              <div class="icon" *ngIf="social.kinoTeatr">
                <a [href]="social.kinoTeatr" target="_blank">
                  <i class="icon-kinoteatr"></i>
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>

      <div class="col-lg-2 social pt-2" *ngIf="social">
        <div class="wrap">
          <div class="icon" *ngIf="social.facebook">
            <a [href]="social.facebook" target="_blank">
              <i class="icon-facebook"></i>
            </a>
          </div>
          <div class="icon" *ngIf="social.instagram">
            <a [href]="social.instagram" target="_blank">
              <i class="icon-instagram"></i>
            </a>
          </div>
          <div class="icon" *ngIf="social.youtube">
            <a [href]="social.youtube" target="_blank">
              <i class="icon-youtube"></i>
            </a>
          </div>
          <div class="icon" *ngIf="social.kinoLift">
            <a [href]="social.kinoLift" target="_blank">
              <i class="icon-k-social"></i>
            </a>
          </div>
          <div class="icon" *ngIf="social.kinoTeatr">
            <a [href]="social.kinoTeatr" target="_blank">
              <i class="icon-kinoteatr"></i>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</header>
