import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {Actor} from "../../../models/actor";
import {MainService} from "../../../services/main.service";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {v4 as uuidv4} from 'uuid';

@Component({
  selector: 'app-actor-details',
  templateUrl: './actor-details.component.html',
  styleUrls: ['./actor-details.component.sass']
})
export class ActorDetailsComponent implements OnInit, OnDestroy, AfterViewChecked {
  mansory = false;
  init = false;
  actor: Actor;
  id: number;

  venoboxInit = false;

  hasPhotos: boolean;
  hasVideos: boolean;
  hasSkills: boolean;

  photoChecked: boolean;
  videoChecked: boolean;
  skillChecked: boolean;

  isLayout: boolean;
  isLayout2: boolean;
  isLayout3: boolean;

  constructor(private main: MainService, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer, private titleService: Title) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = +params.get('id');
    });

    if (this.id) {
      this.main.loadActor(this.id).subscribe((data: Actor) => {
        this.actor = data;

        this.titleService.setTitle(this.actor.fullName + ' | Актёрское агенство ОБЪЕКТИВ');

        this.hasPhotos = this.actor.photos.length > 0;
        this.hasSkills = this.actor.skills.length > 0;
        this.hasVideos = this.actor.videos.length > 0;

        if (this.hasVideos) {
          this.actor.videos.forEach(video => {
            video.name = video.name ? video.name : "";
            if (video.videoId) {
              video.videoUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/' + video.videoId);
              video.poster = `url('http://img.youtube.com/vi/${video.videoId}/0.jpg')`;
            } else {
              video.localVideoUniqueId = uuidv4();
            }
          });
        }

        if (this.hasSkills) {
          this.actor.skills.forEach(skill => {
            if (skill.videoId) {
              skill.videoUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/' + skill.videoId);
              skill.poster = `url('http://img.youtube.com/vi/${skill.videoId}/0.jpg')`;
            } else {
              skill.localVideoUniqueId = uuidv4();
            }
          });
        }

        if (this.actor.portfolios.length > 0) {
          this.actor.portfolios.forEach(portfolio => {
            portfolio.descriptionSafe = this.sanitizer.bypassSecurityTrustHtml(portfolio.description);
          });
        }

        this.photoChecked = this.hasPhotos;
        this.videoChecked = !this.hasPhotos && this.hasVideos;
        this.skillChecked = !this.hasPhotos && !this.hasVideos && this.hasSkills;

        this.isLayout3 = this.hasPhotos && this.hasVideos && this.hasSkills;
        this.isLayout2 = (this.hasPhotos && this.hasVideos && !this.hasSkills) || (this.hasPhotos && !this.hasVideos && this.hasSkills) || (!this.hasPhotos && this.hasVideos && this.hasSkills);
        this.isLayout = (this.hasPhotos && !this.hasVideos && !this.hasSkills) || (!this.hasPhotos && this.hasVideos && !this.hasSkills) || (!this.hasPhotos && !this.hasVideos && this.hasSkills);
      });
    }
  }

  ngOnDestroy(): void {
    let vbClose = $(".vbox-close");
    if (vbClose.length > 0){
      vbClose.click();
    }
  }

  ngAfterViewChecked(): void {
    let mansory = $('.mansory-gallery');
    if (mansory.length > 0 && !this.mansory) {
      this.mansory = true;
      //@ts-ignore
      $(".grid").imagesLoaded(function () {
        //@ts-ignore
        $(".grid").masonry({
          columnWidth: '.grid-item',
          itemSelector: '.grid-item',
          isAnimated: true,
          percentPosition: true,
          horizontalOrder: true,
        });
      });
    }

    let venobox = $(".venobox");
    if (venobox.length > 0 && !this.venoboxInit) {
      this.venoboxInit = true;
      this.initVenoboxGallery();
    }
  }

  initVenoboxGallery() {
    let venobox = $(".venobox");
    if (venobox.length > 0) {
      // @ts-ignore
      venobox.venobox({
        closeColor: "#f4f4f4",
        spinColor: "#f4f4f4",
        arrowsColor: "#f4f4f4",
        closeBackground: "#17191D",
        overlayColor: "rgba(23,25,29,0.8)",
        spinner: "three-bounce",
        autoplay: true
      });
    }
  }

}
