import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {MainService} from '../../services/main.service';
import {Event} from "../../models/event";
import {Settings} from "../../models/settings";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit, AfterViewChecked {

  init = false;

  title = 'Актерское агентство ОБЪЕКТИВ';

  events$: Event[];
  first: Event;
  settings: Settings;

  eventDates: Date[];

  config = {
    dots: false,
    arrows: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: '<i class="icon-slick-next"></i>',
    prevArrow: '<i class="icon-slick-prev"></i>',
  };

  counter: HTMLDivElement;

  constructor(private titleService: Title, private mainService: MainService, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);

    this.mainService.loadNearestSameDayEvents().subscribe((data: Event[]) => {
      this.events$ = data;
    });

    this.mainService.loadSettings().subscribe((data: Settings) => {
      this.settings = data;
      if (this.settings.videoYoutubeId){
        this.settings.videoYoutubeUrlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.settings.videoYoutubeId}?autoplay=1&mute=1&loop=1`);
      }
    });
  }

  ngAfterViewChecked(): void {
    let video = $('.primary-row-video').find('video')[0] as HTMLVideoElement;

    if (!this.init && video) {
      this.init = true;
      video.muted = true;
    }
  }

  toggleSound(event, videoId) {
    let img = event.target;
    let video = document.getElementById(videoId) as HTMLVideoElement;
    if (video.muted) {
      video.muted = false;
      img.src = "assets/img/volume.svg";
      img.alt = img.title = "Выключить звук";
    } else {
      video.muted = true;
      img.src = "assets/img/volume-mute.svg";
      img.alt = img.title = "Включить звук";
    }
  }

  slickInit(e) {
    let slick = e.slick;
    if (slick.slideCount > 1){
      this.counter = document.createElement('div');
      this.counter.classList.add('slider--counter');

      let currentSlide = slick.currentSlide;
      let i = (currentSlide ? currentSlide : 0) + 1;
      $(this.counter).html(`<span class="current">${i}</span> <span class="total"> / ${slick.slideCount}</span>`);

      $('.event-block-post').append(this.counter);
    }
  }

  afterChange(e) {
    let slick = e.slick;
    let currentSlide = slick.currentSlide;
    let i = (currentSlide ? currentSlide : 0) + 1;
    $(this.counter).html(`<span class="current">${i}</span> <span class="total"> / ${slick.slideCount}</span>`);
  }
}
