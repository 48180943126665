<app-actor-details-header [actor]="actor"></app-actor-details-header>

<div class="container-fluid container-profile-page after-profile pr-0 pl-0 pb-0">
  <div class="row profile-row m-0 w-100" *ngIf="actor as actor">
    <div class="row profile-row-item m-0 w-100" [accessKey]="actor.id">

      <div class="col-12 col-lg-7 profile-tabs p-0">
        <div [class.layout]="isLayout" [class.layout-2]="isLayout2" [class.layout-3]="isLayout3">

          <input name="nav" type="radio" class="nav home-radio" id="profileGallery" [attr.checked]="photoChecked ? 'checked' : null" *ngIf="hasPhotos"/>
          <div class="page gallery profile-content-page" *ngIf="actor.photos as photos">
            <div class="page-contents row mansory-gallery grid" >

              <div class="grid-item" *ngFor="let photo of photos">
                <a [href]="photo" class="venobox vbox-item" data-gall="photoGallery" >
                  <img [src]="photo" [alt]="actor.fullName">
                </a>
              </div>

            </div>
          </div>
          <label class="nav" for="profileGallery" *ngIf="actor.photos.length > 0">
            <span><i class="icon-gallery"></i>Фото</span>
          </label>

          <input name="nav" type="radio" class="about-radio" id="profileVideo" [attr.checked]="videoChecked ? 'checked' : null" *ngIf="hasVideos"/>
          <div class="page profile-video profile-content-page" *ngIf="actor.videos as videos">
                <div class="videos w-100">
                  <ng-container *ngFor="let video of videos">
                    <div class="video" [style.background-image]="video.poster" [style.padding-top]="video.poster ? '56.25%' : '0'">

                      <a *ngIf="video.videoUrlSafe" class="venobox vbox-item" data-autoplay="true" data-vbtype="video"
                         [title]="video.name" [href]="video.videoUrlSafe" data-gall="videoGallery">
                        <ng-container *ngTemplateOutlet="videoPlaceholder"></ng-container>
                      </a>

                      <a *ngIf="video.localVideoUrl" class="venobox vbox-item" data-autoplay="true" data-vbtype="inline"
                         [title]="video.name" href="{{'#' + video.localVideoUniqueId}}" data-gall="videoGallery">

                        <div [id]="video.localVideoUniqueId" hidden>
                          <video controls>
                            <source [src]="video.localVideoUrl">
                          </video>
                        </div>

                        <video class="w-100 h-100">
                          <source [src]="video.localVideoUrl">
                        </video>
                        <ng-container *ngTemplateOutlet="videoPlaceholder"></ng-container>
                      </a>

                    </div>

                    <ng-template #videoPlaceholder>
                      <i class="icon-play-video"></i>
                      <div class="actor-item-name" *ngIf="video.name">
                        <div class="objective-icon">
                          <img src="/assets/img/objective.svg" alt="O">
                        </div>
                        <div class="name" [title]="video.name">
                          {{video.name}}
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
          </div>
          <label class="nav" for="profileVideo" *ngIf="actor.videos.length > 0">
            <span><i class="icon-video-player"></i>Видео</span>
          </label>

          <input name="nav" type="radio" class="contact-radio" id="profileSkills" [attr.checked]="skillChecked ? 'checked' : null" *ngIf="hasSkills" />
          <div class="page skills profile-content-page" *ngIf="actor.skills as skills">
            <div class="videos w-100">
              <ng-container *ngFor="let skill of skills">
                <div class="video" [style.background-image]="skill.poster" [style.padding-top]="skill.poster ? '56.25%' : '0'">

                  <a *ngIf="skill.videoUrlSafe" class="venobox vbox-item" data-autoplay="true" data-vbtype="video"
                     [title]="skill.name" [href]="skill.videoUrlSafe" data-gall="skillGallery">
                    <ng-container *ngTemplateOutlet="skillPlaceholder"></ng-container>
                  </a>

                  <a *ngIf="skill.localVideoUrl" class="venobox vbox-item" data-autoplay="true" data-vbtype="inline"
                     [title]="skill.name" href="{{'#' + skill.localVideoUniqueId}}" data-gall="skillGallery">

                    <div [id]="skill.localVideoUniqueId" hidden>
                      <video controls>
                        <source [src]="skill.localVideoUrl">
                      </video>
                    </div>

                    <video class="w-100 h-100">
                      <source [src]="skill.localVideoUrl">
                    </video>
                    <ng-container *ngTemplateOutlet="skillPlaceholder"></ng-container>
                  </a>

                </div>
                <ng-template #skillPlaceholder>
                  <i class="icon-play-video"></i>
                  <div class="actor-item-name" *ngIf="skill.name">
                    <div class="objective-icon">
                      <img src="/assets/img/objective.svg" alt="O">
                    </div>
                    <div class="name" [title]="skill.name">
                      {{skill.name}}
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>
          <label class="nav" for="profileSkills" *ngIf="actor.skills.length > 0">
            <span><i class="icon-custome-masks"></i>Навыки</span>
          </label>
        </div>
      </div>

      <div class="col-12 col-lg-5 profile-actor-info">
        <div class="profile-actor-info-wrap">
          <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="profile-actor" checked>
              <label class="tab-label profile-actor-info-profile" for="profile-actor">Профиль</label>
              <div class="tab-content">
                <div class="profile-table">
                  <table>
                    <tr *ngIf="actor.birthday">
                      <td>Дата рождения</td>
                      <td class="profile-table-weight">{{actor.birthday}}</td>
                    </tr>
                    <tr *ngIf="actor.placeOfBirth">
                      <td>Место рождения</td>
                      <td class="profile-table-weight">{{actor.placeOfBirth}}</td>
                    </tr>
                    <tr *ngIf="actor.growth">
                      <td>Рост</td>
                      <td class="profile-table-weight">{{actor.growth}}</td>
                    </tr>
                    <tr *ngIf="actor.weight">
                      <td>Вес</td>
                      <td class="profile-table-weight">{{actor.weight}}</td>
                    </tr>
                    <tr *ngIf="actor.eyeColor">
                      <td>Цвет глаз</td>
                      <td class="profile-table-weight">{{actor.eyeColor}}</td>
                    </tr>
                    <tr *ngIf="actor.hairColor">
                      <td>Цвет волос</td>
                      <td class="profile-table-weight">{{actor.hairColor}}</td>
                    </tr>
                    <tr *ngIf="actor.bodyType">
                      <td>Телосложение</td>
                      <td class="profile-table-weight">{{actor.bodyType}}</td>
                    </tr>
                    <tr *ngIf="actor.appearanceType">
                      <td>Тип внешности</td>
                      <td class="profile-table-weight">{{actor.appearanceType}}</td>
                    </tr>
                    <tr *ngIf="actor.nationality">
                      <td>Национальность</td>
                      <td class="profile-table-weight">{{actor.nationality}}</td>
                    </tr>
                    <ng-container *ngIf="actor.additionalParameters">
                      <tr *ngFor="let parameter of actor.additionalParameters">
                        <td>{{parameter.name}}</td>
                        <td class="profile-table-weight">{{parameter.description}}</td>
                      </tr>
                    </ng-container>
                  </table>
                </div>
              </div>
            </div>

            <div class="tab" *ngFor="let portfolio of actor.portfolios">
              <input type="checkbox" [id]="'actor-portfolio-' + portfolio.id" checked>
              <label class="tab-label profile-actor-info-profile" [for]="'actor-portfolio-' + portfolio.id">{{portfolio.name}}</label>
              <div class="tab-content">
                <div class="profile-actor-info-description" [innerHTML]="portfolio.descriptionSafe"></div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</div>
