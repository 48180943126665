<div class="container-fluid primary-container" *ngIf="settings && settings.showEventBlock">
  <div class="primary-row">

    <!-- VIDEO -->
      <div class="primary-row-video" *ngIf="settings.video">
        <video id="video" class="video" loop="loop" autoplay="autoplay">
          <source [src]="settings.video" type="video/mp4"/>
        </video>
        <div class="controls">
          <button (click)="toggleSound($event, 'video')">
            <img src="assets/img/volume-mute.svg" alt="Включить звук" title="Включить звук">
          </button>
        </div>
      </div>

    <div class="primary-row-video" *ngIf="settings.videoYoutubeUrlSafe">
      <iframe [src]="settings.videoYoutubeUrlSafe" allowfullscreen="allowfullscreen" frameborder="0" ></iframe>
    </div>

    <!-- EVENTS -->
    <div class="event-block"
         *ngIf="events$ && events$.length > 0">

      <ngx-slick-carousel class="event-block-post" [config]="config" (init)="slickInit($event)"
                          (afterChange)="afterChange($event)">
        <div ngxSlickItem class="event-post-content row" *ngFor="let event of events$">

          <div class="event-content-actor" [routerLink]="event.link">
            <img src="{{event.imageUrl}}" alt="">
            <h3 class="event-content-actor-name">{{event.actor}}</h3>
          </div>
          <div class="event-content-text" [routerLink]="event.link">
            <div class="event-content-heading">
              <h1 [title]="event.title">{{event.title}}</h1>
            </div>
            <div class="event-content-description" [innerHTML]="event.description"></div>
          </div>
          <div class="col-12 event-content-data">
            <div class="event-content-data-day">
              <i class="icon-calendar"></i>
              <p>{{event.dateTimeString}}</p>
            </div>
            <div class="event-content-data-location">
              <i class="icon-point"></i>
              <p [title]="event.address">{{event.address}}</p>
            </div>
          </div>

        </div>
      </ngx-slick-carousel>
    </div>

  </div>
</div>

<app-actor-slides></app-actor-slides>
