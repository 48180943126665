<div class="container-fluid p-0 header-white">
  <div class="row m-0 w-100">
    <div class="col-12 col-sm-4 col-lg-5 heading">
      <h2>Актёрам</h2>
    </div>
  </div>
</div>

<div class="content-page" [innerHTML]="content">
</div>
