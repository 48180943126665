import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/shared/header/header.component';
import {HomeComponent} from './components/home/home.component';
import {FooterComponent} from './components/shared/footer/footer.component';
import {EventsComponent} from './components/events/events.component';
import {MediaComponent} from './components/media/media.component';
import {EventDetailsComponent} from './components/events/event-details/event-details.component';
import {ActorsComponent} from './components/actors/actors.component';
import {ActorDetailsComponent} from './components/actors/actor-details/actor-details.component';
import {ActorSlidesComponent} from './components/shared/actor-slides/actor-slides.component';
import {ActorsHeaderComponent} from './components/actors/header/header.component';
import {ActorDetailsHeaderComponent} from './components/actors/actor-details/header/header.component';
import {EventsHeaderComponent} from './components/events/header/header.component';
import {EventDetailsHeaderComponent} from './components/events/event-details/header/header.component';
import {MediaHeaderComponent} from './components/media/header/header.component';
import { ToActorsComponent } from './components/to-actors/to-actors.component';
import { ContactsComponent } from './components/contacts/contacts.component';

import {EventEmitterService} from "./services/event-emitter.service";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    EventsComponent,
    MediaComponent,
    EventDetailsComponent,
    ActorsComponent,
    ActorDetailsComponent,
    ActorSlidesComponent,
    ActorsHeaderComponent,
    ActorDetailsHeaderComponent,
    EventsHeaderComponent,
    EventDetailsHeaderComponent,
    MediaHeaderComponent,
    ToActorsComponent,
    ContactsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SlickCarouselModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule
  ],
  providers: [
    Title,
    EventEmitterService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
