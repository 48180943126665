import { Injectable, EventEmitter } from '@angular/core';
import {Subscription} from "rxjs/internal/Subscription";

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeClickEventTagFunction = new EventEmitter();
  eventSubs: Subscription;

  invokeClickMediaTagFunction = new EventEmitter();
  mediaSubs: Subscription;

  constructor() { }

  onEventTagClick(event, id: number){
    this.invokeClickEventTagFunction.emit({event: event, id: id});
  }

  onMediaTagClick(event, id: number){
    this.invokeClickMediaTagFunction.emit({event: event, id: id});
  }
}
