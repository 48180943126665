import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {MainService} from "../../services/main.service";

@Component({
  selector: 'app-actors',
  templateUrl: './actors.component.html',
  styleUrls: ['./actors.component.sass']
})
export class ActorsComponent implements OnInit, OnDestroy {

  title = 'Актёры | Актёрское агентсво ОБЪЕКТИВ';

  routerLink: string;

  constructor(private mainService: MainService, private titleService: Title, private router: Router) {
  }

  ngOnInit(): void {
    this.mainService.setAfterWhiteClass("");
    this.routerLink = this.router.url;

    if (this.routerLink === '/actors') {
      this.title = 'Актёры | Актёрское агентсво ОБЪЕКТИВ';
    } else if (this.routerLink === '/actresses') {
      this.title = 'Актрисы | Актёрское агентсво ОБЪЕКТИВ';
    } else if (this.routerLink === '/child-actors') {
      this.title = 'Новые лица | Актёрское агентсво ОБЪЕКТИВ';
    }

    this.titleService.setTitle(this.title);
  }

  ngOnDestroy(): void {
    this.mainService.setAfterWhiteClass("");
  }
}
