<div class="container-fluid container-actors" [class]="afterWhiteClass">
  <div class="row main-row-actors" *ngIf="actors$ as actors">
    <div class="col-lg-3 col-12 col-md-6 actor-item" *ngFor="let actor of actors"
    (mouseenter)="actor.hover = true; getActorPhotos(actor)" (mouseleave)="actor.hover = false">
      <a [routerLink]="actor.link" class="profile-page" [accessKey]="actor.id">

        <div id="actor-item-slider-{{actor.id}}" class="actor-item-slider" *ngIf="actor.photos && actor.photos.length > 0; else photoPlaceholder">
          <div class="slide" *ngFor="let photo of actor.photos">
            <img class="actor-item-image" [src]="photo" [alt]="actor.fullName" />
          </div>
        </div>

        <ng-template class="actor-item-slider" #photoPlaceholder>
          <div class="actor-item-slider">
            <div class="slide">
              <img class="actor-item-image" src="/assets/img/actors/photo-placeholder.jpg" [alt]="actor.fullName" />
            </div>
          </div>
        </ng-template>

        <div class="actor-item-name see-profile" *ngIf="actor.hover; else actorHoverFalse">
          Смотреть профиль
        </div>
        <ng-template #actorHoverFalse>
          <div class="actor-item-name">
            <div class="objective-icon">
              <img src="assets/img/objective.svg" alt="O">
            </div>
            <div class="name" [title]="actor.fullName">
              {{actor.fullName}}
            </div>
          </div>
        </ng-template>
      </a>
    </div>
  </div>
</div>
