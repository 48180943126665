import {AfterViewInit, Component} from '@angular/core';
import {slideInAnimation} from "./animations";
import {NavigationEnd, Router} from "@angular/router";

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  animations: [slideInAnimation]
})

export class AppComponent implements AfterViewInit {
  constructor(public router: Router) {
    if (window.location.hostname === "objective-agency.ru"){
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd){
          gtag('config', 'G-9WJ58838J1', {
            'page_path': event.urlAfterRedirects
          });
        }
      });
    }
  }

  title: string;

  ngAfterViewInit(): void {
    let loader = document.getElementById('loader');
    setTimeout(function () {
      $(loader).fadeOut(500, function () {
        this.remove();
      });
    }, 1000);
  }
}
