<div class="container-fluid p-0 header-white header-before-mainheader-sticky" *ngIf="actor">
  <div class="row header-profile m-0">
    <div class="col-12 col-md-3 col-lg-4 header-profile-heading">
      <h2>{{actor.fullName}}</h2>
      <div class="header-profile-social-media">
        <div class="header-profile-inst header-profile-social-icon-media" *ngIf="actor.instagram">
          <a [href]="actor.instagram" target="_blank">
            <img src="../../../../../assets/img/profile-social/instagram-profile.svg" alt="Instagram">
            <!-- <i class="icon-instagram"></i> -->
          </a>
        </div>
        <div class="header-profile-facebook header-profile-social-icon-media" *ngIf="actor.facebook">
          <a [href]="actor.facebook" target="_blank">
            <img src="../../../../../assets/img/profile-social/facebook-profile.svg" alt="Facebook">
            <!-- <i class="icon-facebook"></i> -->
          </a>
        </div>
        <div class="header-profile-youtube header-profile-social-icon-media" *ngIf="actor.youtube">
          <a [href]="actor.youtube" target="_blank">
            <img src="../../../../../assets/img/profile-social/youtube-profile.svg" alt="Youtube">
            <!-- <i class="icon-youtube"></i> -->
          </a>
        </div>
        <div class="header-profile-k-social header-profile-social-icon-media" *ngIf="actor.kino">
          <a [href]="actor.kino" target="_blank">
            <img src="../../../../../assets/img/profile-social/kino-profile.svg" alt="Kino">
            <!-- <i class="icon-k-social"></i> -->
          </a>
        </div>
        <div class="header-profile-social-kino header-profile-social-icon-media" *ngIf="actor.kinoTeatr">
          <a [href]="actor.kinoTeatr" target="_blank">
            <img src="../../../../../assets/img/profile-social/kinoteatr-profile.svg" alt="KinoTeatr">
            <!-- <i class="icon-custome-masks"></i> -->
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 col-lg-8 header-profile-info">
      <div class="header-profile-social">
        <div class="header-profile-inst header-profile-social-icon" *ngIf="actor.instagram">
          <a [href]="actor.instagram" target="_blank">
            <img src="../../../../../assets/img/profile-social/instagram-profile.svg" alt="Instagram">
            <!-- <i class="icon-instagram"></i> -->
          </a>
        </div>
        <div class="header-profile-facebook header-profile-social-icon" *ngIf="actor.facebook">
          <a [href]="actor.facebook" target="_blank">
            <img src="../../../../../assets/img/profile-social/facebook-profile.svg" alt="Facebook">
            <!-- <i class="icon-facebook"></i> -->
          </a>
        </div>
        <div class="header-profile-youtube header-profile-social-icon" *ngIf="actor.youtube">
          <a [href]="actor.youtube" target="_blank">
            <img src="../../../../../assets/img/profile-social/youtube-profile.svg" alt="Youtube">
            <!-- <i class="icon-youtube"></i> -->
          </a>
        </div>
        <div class="header-profile-k-social header-profile-social-icon" *ngIf="actor.kino">
          <a [href]="actor.kino" target="_blank">
            <img src="../../../../../assets/img/profile-social/kino-profile.svg" alt="Kino">
            <!-- <i class="icon-k-social"></i> -->
          </a>
        </div>
        <div class="header-profile-social-kino header-profile-social-icon" *ngIf="actor.kinoTeatr">
          <a [href]="actor.kinoTeatr" target="_blank">
            <img src="../../../../../assets/img/profile-social/kinoteatr-profile.svg" alt="KinoTeatr">
            <!-- <i class="icon-custome-masks"></i> -->
          </a>
        </div>
      </div>
      <div class="header-profile-agent" *ngIf="actor.agent">
        <p>Агент</p>
        <h5>{{actor.agent.fullName}}</h5>
      </div>
      <div class="header-profile-contacts" *ngIf="actor.agent">
        <a class="header-profile-contacts-mail" href="{{'mailto:' + actor.agent.email}}">{{actor.agent.email}}</a>
        <a class="header-profile-contacts-phone" href="{{'tel:' + actor.agent.phone}}">{{actor.agent.phone}}</a>
      </div>
    </div>
  </div>
</div>
