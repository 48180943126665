import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeHtml, Title} from "@angular/platform-browser";
import {MainService} from "../../services/main.service";

@Component({
  selector: 'app-to-actors',
  templateUrl: './to-actors.component.html',
  styleUrls: ['./to-actors.component.sass']
})
export class ToActorsComponent implements OnInit {

  content: SafeHtml;

  constructor(private titleService: Title, private mainService: MainService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.titleService.setTitle('Актёрам | Актёрское агенство ОБЪЕКТИВ');

    this.mainService.loadPageContent('toActors').subscribe((data: string) => {
      this.content = this.sanitizer.bypassSecurityTrustHtml(data);
    });
  }

}
