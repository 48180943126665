import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeHtml, Title} from "@angular/platform-browser";
import {MainService} from "../../services/main.service";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.sass']
})
export class ContactsComponent implements OnInit {

  content: SafeHtml;

  constructor(private titleService: Title, private mainService: MainService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.titleService.setTitle('Контакты | Актёрское агенство ОБЪЕКТИВ');

    this.mainService.loadPageContent('contacts').subscribe((data: string) => {
      this.content = this.sanitizer.bypassSecurityTrustHtml(data);
    });
  }

}
