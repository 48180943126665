import {Component, Input, OnInit} from '@angular/core';
import {Tag} from "../../../../models/tag";

@Component({
  selector: 'app-event-details-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class EventDetailsHeaderComponent implements OnInit {

  @Input()
  title: string;

  @Input()
  tags: Tag[];

  constructor() {
  }

  ngOnInit(): void {
  }
}
