<!--<app-media-header></app-media-header>-->

<div class="container-fluid p-0 header-white">
  <div class="row m-0 w-100">
    <div class="col-12 col-sm-4 col-lg-5 heading">
      <h2>Медиа</h2>
    </div>
    <div class="col-12 col-sm-8 col-lg-7 tags">
      <div class="talents-block">
        <span class="tag active" (click)="onTagClick($event, null)">#Все медиа</span>
        <span class="tag" *ngFor="let tag of tags$" (click)="onTagClick($event, tag.id)">#{{tag.name}}</span>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid container-media-page">
  <section class="content__section">
    <div class="playlist__one" *ngIf="medias$ as medias">
      <div class="videos">
        <ng-container *ngFor="let media of medias">
          <div class="video media-video"  [style.background-image]="media.poster">

            <a *ngIf="media.youtubeLink" class="venobox vbox-item" data-autoplay="true" data-vbtype="video"
               [title]="media.title" [href]="media.youtubeLink" data-gall="myGallery">
              <ng-container *ngTemplateOutlet="mediaPlaceholder"></ng-container>
            </a>

            <a *ngIf="media.uploadedVideoSrc" class="venobox vbox-item" data-autoplay="true" data-vbtype="inline"
               [title]="media.title" href="{{'#' + media.uploadedVideoUniqueId}}" data-gall="myGallery">

              <div hidden [id]="media.uploadedVideoUniqueId">
                <video controls>
                  <source [src]="media.uploadedVideoSrc" type="video/mp4">
                  Your browser does not support HTML video.
                </video>
              </div>
              <ng-container *ngTemplateOutlet="mediaPlaceholder"></ng-container>
            </a>

          </div>

          <ng-template #mediaPlaceholder>
            <i class="icon-play-video"></i>
            <div class="actor-item-name" *ngIf="media.title">
              <div class="objective-icon">
                <img src="/assets/img/objective.svg" alt="O">
              </div>
              <div class="name" [title]="media.title">
                {{media.title}}
              </div>
            </div>
          </ng-template>

        </ng-container>


      </div>
    </div>
  </section>
</div>

<!--<div class="container-fluid container-media-page">-->
<!--  <div class="row m-0"  *ngIf="medias$ as medias">-->
<!--    <div class="col-12 col-sm-6 col-md-4" *ngFor="let media of medias">-->

<!--      <ng-container #placeholder *ngIf="videoDisabled; else videoBlock">-->
<!--        <div class="media-placeholder" [style.background-image]="media.poster">-->
<!--          &lt;!&ndash; Play / Pause button &ndash;&gt;-->
<!--          <div class="video-play" (click)="playVideo($event, media.uploadedVideoSrc)">-->
<!--            <i class="icon-play-video"></i>-->
<!--          </div>-->
<!--          &lt;!&ndash; Media title &ndash;&gt;-->
<!--          <div class="actor-item-name" *ngIf="media.title">-->
<!--            <div class="objective-icon">-->
<!--              <img src="assets/img/objective.svg" alt="O">-->
<!--            </div>-->
<!--            <div class="name" [title]="media.title">-->
<!--              {{media.title}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </ng-container>-->

<!--      <ng-template #videoBlock>-->
<!--        <div class="video-block">-->
<!--          <ng-container *ngIf="media.youtubeLink; else uploadedVideo">-->
<!--            <iframe [src]="media.youtubeLink" allowfullscreen></iframe>-->
<!--          </ng-container>-->
<!--        </div>-->
<!--      </ng-template>-->

<!--      <ng-template #uploadedVideo>-->
<!--        <video [src]="media.uploadedVideoSrc" controls></video>-->
<!--      </ng-template>-->

<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<!--<div class="container-fluid container-media-page">-->
<!--  <div class="row m-0"  *ngIf="medias$ as medias">-->
<!--    <div class="col-12 col-sm-6 col-md-4 media-item" *ngFor="let media of medias" >-->
<!--      <div class="video-item video">-->
<!--        <iframe *ngIf="media.videoSrcSafe" [src]="media.videoSrcSafe" allowfullscreen="allowfullscreen"></iframe>-->
<!--        <div class="video-play" (click)="playVideo($event, media.localVideoSrc)"><i class="icon-play-video"></i></div>-->
<!--        <div class="actor-item-name" *ngIf="media.title">-->
<!--          <div class="objective-icon">-->
<!--            <img src="assets/img/objective.svg" alt="O">-->
<!--          </div>-->
<!--          <div class="name" [title]="media.title">-->
<!--            {{media.title}}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
