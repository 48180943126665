<div class="container-fluid p-0 header-white">
  <div class="row m-0 w-100">
    <div class="col-12 col-sm-7 col-lg-5 heading">
      <h2>{{title}}</h2>
    </div>
    <div class="col-12 col-sm-5 col-lg-7 tags">
      <div class="talents-block">
        <span class="tag active" (click)="filter($event, null)">#Все события</span>
        <span class="tag" *ngFor="let tag of tags" (click)="filter($event, tag.id)">#{{tag.name}}</span>
      </div>
    </div>
  </div>
</div>
