import {AfterViewChecked, Component, OnInit} from '@angular/core';
import {Event} from "../../../models/event";
import {MainService} from "../../../services/main.service";
import {ActivatedRoute} from "@angular/router";
import {DomSanitizer, Title} from "@angular/platform-browser";

declare var ymaps: any;
declare var map: any;

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.sass']
})
export class EventDetailsComponent implements OnInit, AfterViewChecked {

  id: number;
  idChanged = false;
  init = false;

  eventItem$: Event;
  nearestEvents$: Event[] = null;

  slideConfig = {
    dots: false,
    arrows: true,
    slidesToScroll: 1,
    slidesToShow: 2,
    nextArrow: "<i class='nav-btn next-slide icon-slick-next slick-event-next'></i>",
    prevArrow: "<i class='nav-btn prev-slide icon-slick-prev slick-event-prev'></i>",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      }
    ]
  };
  constructor(private titleService: Title, private mainService: MainService, private activatedRoute: ActivatedRoute, private sanitizer: DomSanitizer) {
    activatedRoute.paramMap.subscribe(params => {
      this.idChanged = true;
      this.id = +params.get('id');
    });
  }

  ngAfterViewChecked(): void {
    let events_slider = $('.event-slider-start');
    let eventListItems = $('.event-list-item');
    if(events_slider.length > 0 && eventListItems.length > 2 && !this.init){
      this.init = true;
      //@ts-ignore
      events_slider.slick(this.slideConfig);
    }
    let mapEl = document.getElementById('map');
    if (this.idChanged && this.id > 0 && mapEl) {
      this.idChanged = false;
      this.loadItems(this.id);
      this.scrollTop();
    }

  }

  ngOnInit(): void {
    this.mainService.loadAllNearestEvents().subscribe((data: Event[]) => {
      if (data.length > 0){
        this.nearestEvents$ = data;
      }
    });
  }

  loadItems(eventId: number) {
    if (eventId > 0) {
      this.mainService.loadEventItem(eventId).subscribe((data: Event) => {
        this.eventItem$ = data;
        this.eventItem$.descriptionSafe = this.sanitizer.bypassSecurityTrustHtml(this.eventItem$.description);
        this.titleService.setTitle(this.eventItem$.title + ' | Актёрское агенство ОБЪЕКТИВ');

        this.initYMap();
      });
    }
  }

  initYMap() {
    let coords = this.eventItem$?.coords;
    let zoom = 13;
    if (!coords) {
      coords = '55.755814, 37.617635'; // MOSCOW
      zoom = 10;
    }
    let coordsArr = coords?.split(',');

    function initMap() {
      if (map) {
        map.destroy();
        map = null;
      }

      if (coordsArr) {
        let c1 = coordsArr[0];
        let c2 = coordsArr[1];

        map = new ymaps.Map('map', {
          center: [c1, c2],
          zoom: zoom
        });

        let placemark = new ymaps.Placemark([c1, c2]);

        map.controls.add('zoomControl');
        map.geoObjects.add(placemark);
      }
    }

    ymaps.ready(initMap);
  }

  trackByEventId(index: number, event: Event) {
    return event.id;
  }

  scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

}
