<div class="container-fluid p-0 header-white">
  <div class="row m-0 w-100">
    <div class="col-12 col-sm-6 col-lg-6 heading">
      <h2>{{title}}</h2>
    </div>
    <div class="col-12 col-sm-6 col-lg-6 tags" *ngIf="tags?.length > 0">
      <div class="talents-block">
        <span class="tag" *ngFor="let tag of tags">#{{tag.name}}</span>
      </div>
    </div>
  </div>
</div>
