import {AfterViewChecked, Component, OnDestroy, OnInit} from '@angular/core';
import {MainService} from '../../services/main.service';
import {Media} from "../../models/media";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {Tag} from "../../models/tag";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.sass']
})
export class MediaComponent implements OnInit, AfterViewChecked, OnDestroy {

  // init = false;

  medias$: Media[];
  tags$: Tag[];
  videoThumbnailInit = false;
  venoboxInit = false;

  constructor(private titleService: Title, private mainService: MainService, private sanitizer: DomSanitizer) {
  }

  ngOnDestroy(): void {
    let vbClose = $(".vbox-close");
    if (vbClose.length > 0){
      vbClose.click();
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle('Медиа | Актёрское агентство ОБЪЕКТИВ');

    this.loadMedia(null);

    this.mainService.loadMediaTags().subscribe((data: Tag[]) => {
      this.tags$ = data;
    });
  }

  ngAfterViewChecked(): void {
    let venobox = $(".venobox");
    if (venobox.length > 0 && !this.venoboxInit){
      this.venoboxInit = true;
      this.initVideoGallery();
    }
  }

  loadMedia(tagId) {
    this.mainService.loadMedia(tagId).subscribe((data: Media[]) => {
      this.medias$ = data;
      this.medias$.forEach(media => {
        if (media.youtubeVideoId){
          media.youtubeLink = this.sanitizer.bypassSecurityTrustResourceUrl('https://youtu.be/' + media.youtubeVideoId);
          media.poster = media.poster ? `url('${media.poster}')` : `url('https://img.youtube.com/vi/${media.youtubeVideoId}/0.jpg')`;
        } else {
          media.uploadedVideoUniqueId = uuidv4();
          media.poster = media.poster ? `url('${media.poster}')` : null;
        }
      });
    });
  }

  onTagClick(event, id) {
    let clickedTag = event.target;

    if (clickedTag.classList.contains('active')) {
      return;
    }

    let tags = Array.from(clickedTag.parentElement.children);
    tags.forEach(tag => {
      (tag as Element).classList.remove('active');
    });

    clickedTag.classList.add('active');

    this.loadMedia(id);
  }

  initVideoGallery(){
    let venobox = $(".venobox");
    if (venobox.length > 0){
      // @ts-ignore
     venobox.venobox({
        closeColor: "#f4f4f4",
        spinColor: "#f4f4f4",
        arrowsColor: "#f4f4f4",
        closeBackground: "#17191D",
        overlayColor: "rgba(23,25,29,0.8)",
        spinner: "three-bounce",
        autoplay: true
      });
    }
  }

}
