import {Component, Input, OnInit} from '@angular/core';
import {Tag} from "../../../models/tag";
import {MainService} from "../../../services/main.service";
import {EventEmitterService} from "../../../services/event-emitter.service";

@Component({
  selector: 'app-media-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class MediaHeaderComponent implements OnInit {

  tags$: Tag[];

  constructor(private mainService: MainService, private eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    this.mainService.loadMediaTags().subscribe((data: Tag[]) => {
      this.tags$ = data;
    });
  }

  filter(event, id: number){
    this.eventEmitterService.onMediaTagClick(event, id);
  }

}
