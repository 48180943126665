<app-event-details-header *ngIf="eventItem$" [title]="eventItem$.title"
                          [tags]="eventItem$.tags" [ngClass]="{eventTagsPointer:true}"></app-event-details-header>

<div class="container-fluid container-event-page">
  <div class="row container-event-page-row w-100 m-0">
    <div class="event-item row m-0 w-100">
      <div class="col-md-5 col-lg-4 event-image-map">
        <div class="event-actor-image-block" *ngIf="eventItem$ as eventItem" >
          <img [src]="eventItem.imageUrl" [alt]="eventItem.title">
          <div class="actor-item-name event-actor-name">
            <div class="objective-icon">
              <img src="assets/img/objective.svg" alt="O">
            </div>
            <div class="name">
              {{eventItem.actor}}
            </div>
          </div>
        </div>
        <div class="event-map">
          <div class="event-map-item" id="map"></div>
          <div class="event-map-block-detail" *ngIf="eventItem$ as eventItem">

            <div class="event-content-data-day" *ngFor="let date of eventItem.dates">
                <i class="icon-calendar"></i>
                <p>{{date}}</p>
            </div>

            <div class="event-content-data-location">
              <i class="icon-point"></i>
              <p>{{eventItem.address}}</p>
            </div>

          </div>
        </div>
      </div>

      <div class="col-md-7 col-lg-8 event-info-col" *ngIf="eventItem$ as eventItem">
        <div class="event-info-content" [innerHTML]="eventItem.descriptionSafe">
        </div>
      </div>

    </div>
  </div>
</div>

<div class="container-event-slider container-fluid pb-3" *ngIf="nearestEvents$ as nearestEvents">
  <div class="row container-event-slider-row">
    <div class="event-slider-page-heading col-12">
      <h2>Ближайшие события</h2>
    </div>
    <div class="event-slider-start d-flex pl-3">
      <div class="event-list-item event-item-slider"  *ngFor="let nearest of nearestEvents; trackBy: trackByEventId" >
        <div class="list-item-image-event pointer" [routerLink]="nearest.link">
          <div class="event-list-image">
            <img class="actor-item-image" [src]="nearest.imageUrl" [alt]="nearest.title"/>
          </div>
          <div class="actor-item-name">
            <div class="objective-icon">
              <img src="assets/img/objective.svg" alt="O">
            </div>
            <div class="name" [title]="nearest.actor">
              {{nearest.actor}}
            </div>
          </div>
        </div>
        <div class="list-item-info-event">
          <div class="event-content-info-text">
            <a [routerLink]="nearest.link"><h1>{{nearest.title}}</h1></a>
            <div class="event-text pointer" [routerLink]="nearest.link">
              {{nearest.description}}
            </div>
          </div>
          <div class="event-content-data">
            <div class="event-content-data-day">
              <i class="icon-calendar"></i>
              <p>{{nearest.dateTimeString}}</p>
            </div>
            <div class="event-content-data-location">
              <i class="icon-point"></i>
              <p [title]="nearest.address">{{nearest.address}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
