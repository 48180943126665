<div class="container-fluid p-0 header-white">
  <div class="row m-0 w-100">
    <div class="col-12 col-sm-4 col-lg-5 heading">
      <h2>Медиа</h2>
    </div>
    <div class="col-12 col-sm-8 col-lg-7 tags">
      <div class="talents-block">
        <span class="tag active" (click)="filter($event, null)">#Все медиа</span>
        <span class="tag" *ngFor="let tag of tags$" (click)="filter($event, tag.id)">#{{tag.name}}</span>
      </div>
    </div>
  </div>
</div>
