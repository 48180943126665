import {Component, Input, OnInit} from '@angular/core';
import {Actor} from "../../../../models/actor";

@Component({
  selector: 'app-actor-details-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class ActorDetailsHeaderComponent implements OnInit {

  @Input()
  actor: Actor;

  constructor() { }

  ngOnInit(): void {
  }

}
