<footer id="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="footer-info">
          <div class="footer-agenstv">
            <p>Актерское агентство ОБЪЕКТИВ (С) 2020</p>
          </div>
          <div class="footer-design">
            <p>Design and Development</p>
            <a href="#">
              <img src="assets/img/golive.svg" alt="golive">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
