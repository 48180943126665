import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  afterWhiteClass = "";

  constructor(private http: HttpClient) {
  }

  setAfterWhiteClass(className) {
    this.afterWhiteClass = className;
  }

  getAfterWhiteClass() {
    return this.afterWhiteClass;
  }

  doHttpGet(url: string) {
    return this.http.get(url, {responseType: "json"});
  }

  loadActors(categoryId: number) {
    return this.doHttpGet('/api/actor/get-by-category?categoryId=' + categoryId);
  }

  loadActorPhotos(actorId: number) {
    return this.doHttpGet('/api/actor/get-photos?actorId=' + actorId);
  }

  loadActorsCoverPhotos(categoryId: number){
    return this.doHttpGet('/api/actor/get-cover-photos?categoryId=' + categoryId);
  }

  loadActor(id: number) {
    return this.doHttpGet(`/api/actor/get-actor/${id}`);
  }

  loadNearestSameDayEvents() {
    return this.doHttpGet('/api/event/get-nearest');
  }

  loadAllNearestEvents() {
    return this.doHttpGet(`/api/event/get-all-nearest`);
  }

  loadEventGroup(tagId) {
    return this.doHttpGet(`/api/event/get-group?tagId=${tagId}`);
  }

  loadUpcomingEvents(nearestIds: number[]){
    let param = "";
    if (nearestIds.length > 0){
      for (let i = 0; i < nearestIds.length; i++){
        param += i == 0 ? "?" : "&";
        param += `nearestIds=${nearestIds[0]}`;
      }
    }

    return this.doHttpGet(`/api/event/get-upcoming${param}`);
  }

  loadPastEvents() {
    return this.doHttpGet(`/api/event/get-past`);
  }

  loadEventItem(id: number) {
    return this.doHttpGet(`/api/event/get-event/${id}`);
  }

  loadEventTags() {
    return this.doHttpGet('/api/event-tag/get');
  }

  loadEventDatesByMonth(month: number, year: number){
    return this.doHttpGet(`/api/event/get-by-month?month=${month}&year=${year}`);
  }

  loadEventsByDate(date: string){
    return this.doHttpGet(`/api/event/get-by-date?date=${date}`);
  }

  loadMedia(tagId) {
    return this.doHttpGet(`/api/media/get-all?tagId=${tagId}`);
  }

  loadMediaTags() {
    return this.doHttpGet('/api/media-tag/get');
  }

  loadSettings(){
    return this.doHttpGet('/api/settings/get-main');
  }

  loadSocial(){
    return this.doHttpGet('/api/settings/get-social');
  }

  loadPageContent(page){
    return this.http.get(`/api/settings/get-content?page=${page}`, {responseType: "text"});
  }
}
