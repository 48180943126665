import {Component, OnInit} from '@angular/core';
import {Tag} from 'src/app/models/tag';
import {MainService} from "../../../services/main.service";
import {EventEmitterService} from "../../../services/event-emitter.service";

@Component({
  selector: 'app-events-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class EventsHeaderComponent implements OnInit {

  title = 'События';

  tags: Tag[];

  constructor(private mainService: MainService, private eventEmitterService: EventEmitterService) {
  }

  ngOnInit(): void {
    this.mainService.loadEventTags().subscribe((data: Tag[]) => {
      this.tags = data;
    });
  }

  filter(event, id: number) {
    this.eventEmitterService.onEventTagClick(event, id);
  }
}
