import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {MediaComponent} from './components/media/media.component';
import {EventsComponent} from './components/events/events.component';
import {EventDetailsComponent} from './components/events/event-details/event-details.component';
import {ActorsComponent} from "./components/actors/actors.component";
import {ActorDetailsComponent} from "./components/actors/actor-details/actor-details.component";
import {ToActorsComponent} from "./components/to-actors/to-actors.component";
import {ContactsComponent} from "./components/contacts/contacts.component";

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'actors', component: ActorsComponent},
  {path: 'actor/:id', component: ActorDetailsComponent},
  {path: 'actresses', component: ActorsComponent},
  {path: 'actress/:id', component: ActorDetailsComponent},
  {path: 'child-actors', component: ActorsComponent},
  {path: 'child-actor/:id', component: ActorDetailsComponent},
  {path: 'media', component: MediaComponent},
  {path: 'events', component: EventsComponent},
  {path: 'event/:id', component: EventDetailsComponent},
  {path: 'to-actors', component: ToActorsComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: '**', component: HomeComponent, pathMatch: 'full'},
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
